import "./Footer.scss";
import logo from "../../Assets/logo-white.png";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer id="footer" className="section-footer">
      <div className="container-footer">
        <div className="footer-top">
          <div className="footer-logo">
            <HashLink smooth to="/">
              <img src={logo} alt="logo BSD City" />
            </HashLink>
            <h3 className="footer-logo-title">Marketing Gallery</h3>
            <div className="footer-logo-text">BSD City - Sinarmasland</div>
          </div>
          <div className="footer-sitemap">
            <div className="footer-site-link">
              <div className="footer-menu">
                Peta Situs
                <li className="nav-item">
                  <NavHashLink smooth to="/#about" className="nav-links">
                    About us
                  </NavHashLink>
                </li>
                <li className="nav-item">
                  <NavHashLink smooth to="/#promo" className="nav-links">
                    Promo
                  </NavHashLink>
                </li>
                <li className="nav-item">
                  <NavHashLink
                    smooth
                    to="/#product-commercial"
                    className="nav-links"
                  >
                    Our Commercial
                  </NavHashLink>
                </li>
                <li className="nav-item">
                  <NavHashLink smooth to="/#tenants" className="nav-links">
                    Our Tenants
                  </NavHashLink>
                </li>
                <li className="nav-item">
                  <NavHashLink smooth to="/#location" className="nav-links">
                    Lokasi
                  </NavHashLink>
                </li>
                <li className="nav-item" onClick={scrollToTop}>
                  <a href="/" className="nav-links">
                    Back to Top
                  </a>
                </li>
              </div>
              <div className="footer-map">
                <div className="footer-map-title">Kontak Kami</div>
                <div className="footer-map-text">
                  <FontAwesomeIcon icon={faLocationDot} className="icon" />
                  <span>
                    Lengkong Kulon, Pagedangan, Tangerang Regency, Banten 15331
                  </span>
                </div>
                <div className="footer-map-text">
                  <FontAwesomeIcon icon={faPhone} className="icon" />
                  <span>+6282122005507</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-rights">
          © 2024 BSD City . All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
